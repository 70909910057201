import React from 'react';

const SignupForm = () => (
  <div className="max-w-xs mx-auto w-full flex flex-col items-center">
    <form
      action="https://gmail.us19.list-manage.com/subscribe/post?u=6a93ec4486da88c6a8b8e933f&amp;id=4bb89d5d3a"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      className="w-full text-center validate"
      target="_blank"
      noValidate
    >
      <div className="w-full">
        <input
          type="text"
          name="NAME"
          className="w-full"
          id="mce-NAME"
          placeholder="Name"
        />
      </div>
      <div className="mt-6 w-full">
        <input
          type="email"
          name="EMAIL"
          className="required email w-full"
          id="mce-EMAIL"
          placeholder="Email"
        />
      </div>

      <div css={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
        <input
          type="text"
          name="b_6a93ec4486da88c6a8b8e933f_4bb89d5d3a"
          tabIndex="-1"
        />
      </div>
      <div className="mt-6">
        <button
          type="submit"
          name="subscribe"
          id="mc-embedded-subscribe"
          className="button"
        >
          Sign Up
        </button>
      </div>
    </form>
  </div>
);

export default SignupForm;
