import React from 'react';
import styled from '@emotion/styled';

import Layout from '~/components/layout';
import Container from '~/components/container';
import SEO from '~/components/seo';
import SignupForm from '~/components/signupform';

import Artwork1 from '~/images/Artwork1.svg';
import Artwork2 from '~/images/Artwork2.svg';
import Artwork3 from '~/images/Artwork3.svg';

const IndexPage = () => (
  <Layout>
    <SEO title="Endurabl" />

    <Container className="mt-16">
      <div className="flex flex-col items-start tablet-up:flex-row">
        <div css={{ flex: 1 }} className="max-w-xs tablet-up:mr-8">
          <img src={Artwork1} alt="Artwork" className="w-full object-contain" />
        </div>
        <div css={{ flex: 3 }} className="mt-8 tablet-up:mt-0">
          <h1 className="leading-tight">Endurabl</h1>
          <h3 className="leading-none">
            A support network for strength, confidence, and direction on the
            entrepreneurial journey
          </h3>
        </div>
      </div>
    </Container>

    <Container className="mt-12">
      <div className="text-center">
        We’re focused on connecting entrepreneurs & the people that want to help
        them. Being an entrepreneur is not an easy task; so use this site to
        find someone - or a whole network of people - to help give you the
        direction, strength, and confidence you need to endure.
      </div>
    </Container>

    <Container className="mt-12">
      <div className="w-full h-full bg-x-pink relative py-6">
        <img
          src={Artwork2}
          alt="Artwork"
          className="absolute"
          css={{ width: '5rem', height: '5rem', left: '-1.5rem', top: '2rem' }}
        />
        <div className="flex flex-col tablet-up:flex-row">
          <div css={{ flex: 5 }} className="text-center px-8">
            <h2>Join The Network.</h2>
            <h2 className="stroke-teal">Endure.</h2>
            <h3 className="py-4">
              Sign up to receive updates on when Endurabl is launching,
              features, and all the content you need as an entrepreneur!
            </h3>
          </div>
          <div css={{ flex: 4 }} className="px-4 self-center">
            <SignupForm />
          </div>
        </div>
      </div>
    </Container>

    <Container className="mt-12">
      <div className="eaves text-center text-5xl tablet-up:text-6xl font-extrabold stroke-darkblue">
        Strength | Confidence | Direction
      </div>
    </Container>

    <Container className="mt-12">
      <div className="flex w-full h-full bg-x-darkblue">
        <div className="flex-1 py-6 px-4 tablet-up:px-12 text-center text-x-pink text-2xl font-bold tablet-up:text-4xl eaves">
          Entrepreneurs need coaches, therapists, consultants, and mentors. Use
          this site to find the right fit.
        </div>
        <div>
          <img
            src={Artwork3}
            alt="Artwork"
            className="w-16 h-full object-cover"
          />
        </div>
      </div>
    </Container>

    <Container className="mt-12">
      <div className="max-w-lg mx-auto text-center">
        <h2>More coming soon!</h2>
        <h3>
          Sign up to receive updates on when Endurabl is launching, features,
          and all the content you need as an entrepreneur!
        </h3>

        <div className="mt-12">
          <SignupForm />
        </div>
      </div>
    </Container>
  </Layout>
);

export default IndexPage;
